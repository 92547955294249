const activeClass = 'with-nav';
const toggleNavigation = () => {
	document.documentElement.classList.toggle(activeClass);
};

// Toggle navigation via skiplink
const skiplink: HTMLAnchorElement | null = document.querySelector('#skiplink');
if (skiplink) {
	skiplink.addEventListener(
		'click',
		(event: MouseEvent) => {
			event.preventDefault();
			toggleNavigation();
		},
		false,
	);
}

// Handle close by Escape key
document.addEventListener('keyup', (event: KeyboardEvent) => {
	if (
		document.documentElement.classList.contains(activeClass) &&
		event.key === 'Escape'
	) {
		toggleNavigation();
	}
});

// Prevent clicking the current menu link
const links: NodeListOf<HTMLAnchorElement> =
	document.querySelectorAll('.nav-list a');
for (const link of links) {
	if (link && link.href === location.href) {
		link.addEventListener('click', (event: MouseEvent) => {
			event.preventDefault();
			link.blur();
			if (document.documentElement.classList.contains(activeClass)) {
				toggleNavigation();
			}
		});
	}
}

const now = new Date();
const year: HTMLTimeElement | null = document.querySelector('.events h1 time');
const next: HTMLLinkElement | null = document.querySelector('link[rel="next"]');
const currentMonthLink: HTMLAnchorElement | null = document.querySelector(
	'.action-buttons a:first-child',
);

if (next && year) {
	year.addEventListener('dblclick', (event) => {
		event.preventDefault();
		location.assign(next.href);
	});
}

const isElementInViewport = (element: Element) => {
	const rect = element.getBoundingClientRect();

	return (
		rect.top >= 0 &&
		rect.left >= 0 &&
		rect.bottom <=
			(window.innerHeight || document.documentElement.clientHeight) &&
		rect.right <=
			(window.innerWidth || document.documentElement.clientWidth)
	);
};

if (currentMonthLink) {
	const [url, id] = currentMonthLink.href.split('#');
	const month = document.querySelector(`#${id}`);
	if (
		month &&
		!isElementInViewport(month) &&
		url.includes(String(now.getFullYear()))
	) {
		currentMonthLink.classList.add('btn-pulse');
	}
}

class Accordion {
	readonly #duration: number;
	readonly #details: HTMLDetailsElement;
	readonly #summary: HTMLElement | null;
	#content: HTMLElement | null;
	#animation: Animation | null;
	#isClosing: boolean;
	#isExpanding: boolean;

	constructor(element: HTMLDetailsElement, duration = 300) {
		// Store the <details> element
		this.#details = element;
		// Store the <summary> element
		this.#summary = element.querySelector('summary') ?? null;
		// Store the summary’s content
		this.#content = null;

		// Store the animation object (so we can cancel it if needed)
		this.#animation = null;
		// Store the duration
		this.#duration = duration;
		// Store if the element is closing
		this.#isClosing = false;
		// Store if the element is expanding
		this.#isExpanding = false;
		// Wrap content and detect user clicks on the summary element
		if (this.#summary) {
			this.#wrapContent();
			this.#summary.addEventListener('click', (event) => {
				this.#onClick(event);
			});
		}
	}

	#useAnimation() {
		return (
			'matchMedia' in window &&
			!window.matchMedia('(prefers-reduced-motion: reduce)').matches
		);
	}

	#onClick(event: MouseEvent) {
		// Only animate when allowed
		if (!this.#useAnimation()) {
			return;
		}

		// Stop default behaviour from the browser
		event.preventDefault();
		// Add an overflow on the <details> to avoid content overflowing
		this.#details.style.overflow = 'hidden';
		// Check if the element is being closed or is already closed
		if (this.#isClosing || !this.#details.open) {
			this.#open();
			// Check if the element is being openned or is already open
		} else if (this.#isExpanding || this.#details.open) {
			this.#shrink();
		}
	}

	#wrapContent() {
		const siblings = this.#details.querySelectorAll('summary ~ *');
		if (siblings.length > 0) {
			const wrapper = document.createElement('DIV');
			wrapper.classList.add('content');
			for (const element of siblings) {
				wrapper.append(element);
			}

			this.#content = wrapper;
			this.#details.append(wrapper);
		}
	}

	#shrink() {
		// Set the element as "being closed"
		this.#isClosing = true;

		// Store the current size of the element
		const startSize = this.#details.offsetHeight;
		const startSizePx = `${startSize}px`;
		// Calculate the size of the summary
		const endSize = this.#summary ? this.#summary.offsetHeight : 0;
		const endSizePx = `${endSize}px`;

		// If there is already an animation running
		if (this.#animation) {
			// Cancel the current animation
			this.#animation.cancel();
		}

		// Start a WAAPI animation
		this.#animation = this.#details.animate(
			{
				// Set the keyframes from the startSizePx to endSizePx
				height: [startSizePx, endSizePx],
			},
			{
				duration: (this.#duration / 100) * startSize,
				easing: 'ease-out',
			},
		);

		// When the animation is complete, call `onAnimationFinish()`
		this.#animation.onfinish = () => {
			this.#onAnimationFinish(false);
		};

		// If the animation is cancelled, unset closing state
		this.#animation.addEventListener('cancel', () => {
			this.#isClosing = false;
		});
	}

	#open() {
		// Apply a fixed size on the element
		this.#details.style.height = `${this.#details.offsetHeight}px`;
		// Force the `open` attribute on the details element
		this.#details.open = true;
		// Wait for the next frame to call the expand function
		window.requestAnimationFrame(() => {
			this.#expand();
		});
	}

	#expand() {
		// Set the element as "being expanding"
		this.#isExpanding = true;
		// Get the current fixed size of the element
		const startSizePx = `${this.#details.offsetHeight}px`;
		// Calculate the open size of the element (summary size + content size)
		const endSize =
			this.#summary && this.#content
				? this.#summary.offsetHeight + this.#content.offsetHeight
				: 0;
		const endSizePx = `${endSize}px`;

		// If there is already an animation running
		if (this.#animation) {
			// Cancel the current animation
			this.#animation.cancel();
		}

		// Start a WAAPI animation
		this.#animation = this.#details.animate(
			{
				// Set the keyframes from the startSizePx to endSizePx
				height: [startSizePx, endSizePx],
			},
			{
				duration: (this.#duration / 100) * endSize,
				easing: 'ease-out',
			},
		);

		// When the animation is complete, call onAnimationFinish()
		this.#animation.onfinish = () => {
			this.#onAnimationFinish(true);
		};

		// If the animation is cancelled, isExpanding variable is set to false
		this.#animation.addEventListener('cancel', () => {
			this.#isExpanding = false;
		});
	}

	#onAnimationFinish(open: boolean) {
		// Set the open attribute based on the parameter
		this.#details.open = open;
		// Clear the stored animation
		this.#animation = null;
		// Reset closing and expanding states
		this.#isClosing = false;
		this.#isExpanding = false;
		// Remove the overflow and the fixed height styles
		this.#details.style.height = '';
		this.#details.style.overflow = '';
	}
}

const faq: NodeListOf<HTMLDetailsElement> = document.querySelectorAll('.faq');
for (const item of faq) {
	// eslint-disable-next-line no-new
	new Accordion(item);
}

import isStandalone from 'is-standalone';

if (isStandalone) {
	const links: NodeListOf<HTMLAnchorElement> = document.querySelectorAll(
		'a[download], a[type]',
	);
	for (const link of links) {
		const relations = new Set(...link.rel.split(' '));
		relations.add('noopener');
		relations.add('noreferrer');

		link.setAttribute('target', '_blank');
		link.setAttribute('rel', [...relations.values()].join(' '));
	}
}

const inputs: NodeListOf<HTMLInputElement> = document.querySelectorAll(
	'input[type="password"]',
);
const thresholds = {
	weak: 0,
	medium: 30,
	strong: 69,
};

const checkRepetition = (length: number, password: string) => {
	let result = '';
	let repeated = false;

	for (let i = 0; i < password.length; i++) {
		repeated = true;
		for (
			let position = 0;
			position < length && position + i + length < password.length;
			position++
		) {
			repeated &&=
				password.charAt(position + i) ===
				password.charAt(position + i + length);

			if (position < length) {
				repeated = false;
			}
		}

		if (repeated) {
			i += length - 1;
			repeated = false;
		} else {
			result += password.charAt(i);
		}
	}

	return result;
};

const calculateScore = (password: string) => {
	let score = 0;

	// Password length
	score += password.length * 4;
	score += checkRepetition(1, password).length - password.length;
	score += checkRepetition(2, password).length - password.length;
	score += checkRepetition(3, password).length - password.length;
	score += checkRepetition(4, password).length - password.length;

	// Password has 3 numbers
	if (/((?:.*\d){3})/.test(password)) {
		score += 5;
	}

	// Password has at least 2 symbols
	const regex = '.*[!,@,#,$,%,^,&,*,?,_,~]';
	const symbols = new RegExp('(' + regex + regex + ')');
	if (symbols.test(password)) {
		score += 5;
	}

	// Password has Upper and Lower chars
	if (/([a-z].*[A-Z])|([A-Z].*[a-z])/.test(password)) {
		score += 10;
	}

	// Password has number and chars
	if (/([a-zA-Z])/.test(password) && /(\d)/.test(password)) {
		score += 15;
	}

	// Password has number and symbol
	if (/([!@#$%^&*?_~])/.test(password) && /(\d)/.test(password)) {
		score += 15;
	}

	// Password has char and symbol
	if (/([!@#$%^&*?_~])/.test(password) && /([a-zA-Z])/.test(password)) {
		score += 15;
	}

	// Password is just numbers or chars
	if (/^\w+$/.test(password) || /^\d+$/.test(password)) {
		score -= 10;
	}

	if (score > 100) {
		score = 100;
	}

	if (score < 0) {
		score = 0;
	}

	return score;
};

const setRatingAttributes = (
	dataElm: HTMLDataElement,
	text: string | null,
	strength: string | null,
) => {
	const labelElm = dataElm.previousElementSibling?.previousElementSibling;

	dataElm.classList.remove(
		...Object.keys(thresholds)
			.filter((threshold) => threshold !== strength)
			.map((threshold) => `password-strength-${threshold}`),
	);

	if (text) {
		dataElm.title = `${text}es Passwort`;
	} else {
		dataElm.removeAttribute('title');
	}

	if (labelElm instanceof HTMLLabelElement) {
		labelElm.querySelector('span')?.remove();

		if (text && strength) {
			labelElm.insertAdjacentHTML(
				'beforeend',
				` <span class="password-strength-text password-strength-text-${strength}">${text.toLowerCase()}</span>`,
			);
		}
	}

	if (strength) {
		dataElm.classList.add(`password-strength-${strength}`);
	}
};

const handleInput = (input: HTMLInputElement, data: HTMLDataElement) => {
	const score = calculateScore(input.value);
	data.value = String(score);
	data.setAttribute('aria-valuenow', String(score));
	data.setAttribute(
		'aria-hidden',
		score > thresholds.weak ? 'false' : 'true',
	);
	data.style.width = score > thresholds.weak ? `${score}%` : '';

	if (score > thresholds.strong) {
		setRatingAttributes(data, 'Stark', 'strong');
	} else if (score > thresholds.medium) {
		setRatingAttributes(data, 'Mittelmäßig', 'medium');
	} else if (score > thresholds.weak) {
		setRatingAttributes(data, 'Schwach', 'weak');
	} else {
		setRatingAttributes(data, null, null);
	}
};

for (const input of inputs) {
	const data = input.nextElementSibling as HTMLDataElement | null;
	if (input && data) {
		input.addEventListener('keyup', () => {
			handleInput(input, data);
		});
		if (input.value !== '') {
			handleInput(input, data);
		}
	}
}

const forms = document.querySelectorAll('form');

for (const form of forms) {
	form.addEventListener('submit', (event: Event) => {
		// Prevent if already submitting
		if (form.classList.contains('is-submitting')) {
			event.preventDefault();
		}

		// Add class to hook our visual indicator on
		form.classList.add('is-submitting');
	});
}

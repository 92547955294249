import isStandalone from 'is-standalone';

if (isStandalone) {
	const pStart = {x: 0, y: 0};
	const pCurrent = {x: 0, y: 0};
	let reloadOnTouchEnd = false;

	const handleSpinner = () => {
		reloadOnTouchEnd = true;
		if ('vibrate' in navigator) {
			navigator.vibrate(100);
		}
	};

	const handleTouchStart = (event: TouchEvent) => {
		if (event.targetTouches !== undefined) {
			const {screenX, screenY} = event.targetTouches[0];
			pStart.x = screenX;
			pStart.y = screenY;
		}
	};

	const handleTouchMove = (event: TouchEvent) => {
		if (event.changedTouches !== undefined) {
			const {screenX, screenY} = event.targetTouches[0];
			pCurrent.x = screenX;
			pCurrent.y = screenY;
		}

		const distance =
			pStart.y < pCurrent.y ? Math.abs(pStart.y - pCurrent.y) : 0;
		if (document.documentElement.scrollTop <= 0 && distance > 130) {
			document.documentElement.classList.add('is-loading');
		}
	};

	const handleTouchEnd = () => {
		if (reloadOnTouchEnd) {
			window.setTimeout(() => {
				location.reload();
			}, 200);
		}
	};

	document.body.insertAdjacentHTML('afterbegin', '<div id="loader"></div>');
	document
		.querySelector('#loader')
		?.addEventListener('transitionend', handleSpinner);
	document.addEventListener('touchstart', handleTouchStart);
	document.addEventListener('touchmove', handleTouchMove);
	document.addEventListener('touchend', handleTouchEnd);
}

import {tns, type TinySliderSettings} from 'tiny-slider';

/* eslint-disable @typescript-eslint/naming-convention */
const settings: TinySliderSettings = {
	controlsText: ['Zurück', 'Weiter'],
	loop: false,
	mouseDrag: true,
	navPosition: 'bottom',
	responsive: {
		0: {
			controls: false,
		},
		928: {
			controls: true,
		},
	},
	swipeAngle: false,
	useLocalStorage: false,
	nonce: '71d19d0fe74be4dc4d26bacb8943321c6f807b9b9626b6ce6d8d547130ed40d9',
};
/* eslint-enable @typescript-eslint/naming-convention */

const sliders = document.querySelectorAll('.slider');
for (const container of sliders) {
	tns({
		container,
		...settings,
	});
}

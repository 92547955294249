import {createPopper, type Instance} from '@popperjs/core';

const triggers: NodeListOf<HTMLElement> =
	document.querySelectorAll('[aria-haspopup]');

for (const trigger of triggers) {
	let popperInstance: Instance | undefined;

	const popupId = trigger.getAttribute('aria-controls');
	if (popupId) {
		const popup: HTMLElement | null = document.querySelector(`#${popupId}`);

		if (popup) {
			const toggle = (event: MouseEvent) => {
				event.preventDefault();
				if (popperInstance) {
					popup.setAttribute('aria-hidden', 'true');
					popperInstance.destroy();
					popperInstance = undefined;
				} else {
					popup.setAttribute('aria-hidden', 'false');
					popperInstance = createPopper(trigger, popup);
				}
			};

			trigger.addEventListener('click', toggle);
		}
	}
}
